//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

let selection = [null,null,0,null,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,];
export default {
    name: "App",
    components: {},
    data() {
        return {
            selected: JSON.parse(JSON.stringify(selection)),
            map: {
                //question no: {selection: outcome ...}
                0: { 0: 24, 1: 15, 2: 0 },
                1: { 0: 0, 1: 0 },
                2: { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 },
                3: { 0: 0, 1: 0, 2: 0 },
                4: { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
                5: { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 },
                6: { 0: 0, 1: 20, 2: 20, 3: 20 },
                7: { 0: 0, 1: 21, 2: 22 },
                8: { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
                9: { 0: 0, 1: 0, 2: 0, 3: 10, 4: 10, 5: 0, 6: 10, 7: 10 },
                10: { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0 },
                11: { 0: 0, 1: 0, 2: 0, 3: 0 },
                12: { 0: 0, 1: 20, 2: 20, 3: 20 },
                13: { 0: 0, 1: 20, 2: 20, 3: 20 },
                14: { 0: 0, 1: 0, 2: 10, 3: 20, 4: 20 },
                15: { 0: 0, 1: 10, 2: 10, 3: 10, 4: 20, 5: 10 },
                16: { 0: 0, 1: 0, 2: 0, 3: 0, 4: 20 },
                17: { 0: 0, 1: 10 },
                18: { 0: 0, 1: 10 },
                19: { 0: 0, 1: 10, 2: 10, 3: 10 },
                20: { 0: 0, 1: 10 },
                21: { 0: 0, 1: 0, 2: 0, 3: 30, 4: 30, 5: 40 },
            },
            score: 0,
            states: {0:true,1:true,2:true,3:true,4:true,},
            outcomes: {
                0: "Лицето може да бъде ваксинирано, с която и да е oдобрена от ИАЛ ваксина, следвайки стандартните ваксинационни срокове.",
                10: "Препоръчително е лицето да бъде ваксинирано с mRNA ваксина, следвайки стандартните ваксинационни срокове.",
                15: "Препоръчително е лицето да бъде ваксинирано с Comirnaty (30μg/доза), следвайки стандартните ваксинационни срокове.",
                20: "Препоръчително е лицето да бъде ваксинирано с mRNA ваксина, включваща и трета доза, поставена 28 дни след втората ± четвърта доза, поставена до 6 месеца след третата (при умерен и тежък имунен дефицит).",
                21: "Препоръчително е лицето да бъде ваксинирано с mRNA ваксина, включваща и трета доза, поставена 28 дни след втората ± четвърта доза, поставена до 6 месеца след третата (при умерен и тежък имунен дефицит). *Ваксинацията при пациенти с АВРЗ трябва да се извърши, независимо дали пациентът е прекарал COVID-19.",
                22: "Препоръчително е лицето да бъде ваксинирано с mRNA ваксина, включваща и трета доза, поставена 28 дни след втората ± четвърта доза, поставена до 6 месеца след третата (при умерен и тежък имунен дефицит). *Идеалният момнет за ваксинация е когато заболяването е в ремисия, но тя трябва да се извърши, независимо от активността на заболяването, особено при високо-рискови пациенти, без значение дали пациентът е прекарал COVID-19.",
                24: "Препоръчително е лицето да бъде ваксинирано с Comirnaty (10μg/доза), следвайки стандартните ваксинационни срокове.",
                30: "Лицето може да бъде ваксинирано след консултация и съгласие от алерголог; активно наблюдение до 30 минути след ваксинацията.",
                40: "За момента НЕ СЕ препоръчва ваксинация с ваксината, на която съставки са показали алергична реакция при пациента след първото ѝ прилагане. Препоръчва се консултация с алерголог и преценка за риск/полза от ваксинация.",
            },
            outcome: null,
            modalOutcome: false,
            modalAllergies: false,
            modalChem: false,
            modalLiver: false,
        };
    },
    computed: {
        variant: function() {
            if(this.score >= 30) return "danger";
            else if(this.score >= 10) return "warning";
            else return "info";
        }
    },
    methods: {
        Calculate(event) {
            event.preventDefault();
            event.stopPropagation();

            if(!this.validate()) return;

            this.score = 0;

            for (let i = 0; i < this.selected.length; i++) {
                let arr = this.map[i];
                
                if (arr[this.selected[i]] > this.score) this.score = arr[this.selected[i]];
            }

            this.outcome = this.outcomes[this.score];
            this.modalOutcome = true;
        },
        Reset() {
            this.selected = JSON.parse(JSON.stringify(selection));
            this.states = {0:true,1:true,2:true,3:true,4:true,};
        },
        validate() {
            for(let i=0; i < this.selected.length; i++) {
                if(this.selected[i]==null) {
                    this.$set(this.states,i,false);
                    document.getElementById('field'+i).scrollIntoView();
                    return false;
                }
                else {
                    this.$set(this.states,i,true);
                }
            }
            return true;
        }
    },
    watch: {
        selected: {
            handler(n) {
                for(let i=0; i < n.length; i++) {
                    if(n[i]!=null) {
                        this.$set(this.states,i,true);
                        
                    }
                }
            },
            deep:true
        }
    }
};
